
import Vue, { VueConstructor } from "vue";
import KCrudTable, {
  CrudTableHeader,
} from "@/modules/crudTable/components/KCrudTable.vue";
import { ExportType, PaginatedRequest } from "@/application/api/getPaginated";
import { mapGetters } from "vuex";
import RequiredClientDialog from "@/modules/client/components/RequiredClientDialog.vue";
import {
  clientProductIndex,
  ProductIndexItem,
} from "@/modules/product/api/product";
import ExportButton from "@/components/ExportButton.vue";
import ProductOverviewFilter from "@/modules/product/components/ProductOverviewFilter.vue";

interface ComponentData {
  headers: CrudTableHeader[];
  selected: Partial<ProductIndexItem> & { clientId: number; id: number }[];
  exports: ExportType[];
  filterComponent: VueConstructor;
  productIndexData: ProductIndexItem[];
}

export default Vue.extend({
  name: "ProductTable",
  components: {
    ExportButton,
    RequiredClientDialog,
    KCrudTable,
  },
  data: (): ComponentData => ({
    headers: [
      { value: "dimProduct.articleNumber", sortable: false },
      { value: "dimProduct.productName", sortable: false },
      { value: "revenueCode", sortable: true },
      { value: "stockMinimum", sortable: true },
      { value: "dimProduct.availableStock", sortable: false },
      { value: "dimProduct.totalStock", sortable: false },
      { value: "status", sortable: true },
    ],
    selected: [],
    exports: [],
    filterComponent: ProductOverviewFilter,
    productIndexData: [],
  }),
  computed: {
    ...mapGetters("authorisation", ["client", "isClient"]),
    ...mapGetters("crudTable", ["getRouteSetting"]),
  },
  watch: {
    client: {
      handler() {
        this.resetTable();
      },
      deep: true,
    },
  },
  methods: {
    async productIndex(data: PaginatedRequest) {
      if (!this.client?.id) throw "Client id is not set";
      const response = await clientProductIndex(data, this.client?.id);
      this.productIndexData = response.data.data;
      this.exports = response.data.export ?? [];
      return response;
    },
    handleDetail(item: ProductIndexItem) {
      if (!this.client?.id) throw "Client id is not set";
      this.$router.push({
        name: "product.show",
        params: {
          productId: item.productId.toString(),
          clientId: this.client?.id.toString(),
          products: JSON.stringify(
            this.productIndexData.map((product) => ({
              id: product.productId,
              articleNumber: product.dimProduct.articleNumber,
            }))
          ),
        },
      });
    },
    refreshTable(): void {
      (this.$refs.table as any).getData();
    },
    resetTable(): void {
      (this.$refs.table as any).handleReset();
    },
  },
});
